div.hero{

    .container{
        max-width: 1080px;
        width: 90%;
        margin: 0 auto;

        display: flex;
        flex-wrap: wrap;
        align-items: center;
        height: calc(100vh - 100px);


        @media screen and (max-width: 768px) {
            height: inherit;
            margin-bottom: 100px;
        }

        .slogan{
            overflow: hidden;

            width: 50%;

            @media screen and (max-width: 768px) {
                width: 100%;
            }

            h1{
                @media screen and (max-width: 768px) {
                    font-size: 35px;
                    line-height: 50px;
                }
                font-size:50px;
                font-weight: 700;
                margin-bottom: 20px;
                line-height: 67.5px
            }
        }

        .map-hero{
            width: 50%;


            @media screen and (max-width: 768px) {
                width: 100%;
                padding-left: 0px;
            }

            padding-left: 50px;


            .map-animation{
                width: 100%;
                aspect-ratio: 0.9;
                position: relative;

                @media screen and (max-width: 768px) {
                   //transform: scale(0.7);
                }
    
                .france{
                    width:100%;
                    height: 100%;
                    position: absolute;
                    top:0px;
                    z-index: 5;
                }

                .rennes{
                    position: absolute;
                    top:30%;
                    left: 20%;
                    font-family: 'Mauikea', sans-serif;
                }

                .nancy{
                    position: absolute;
                    top:25%;
                    right: 10%;
                    font-family: 'Mauikea', sans-serif;
                }

                .etape{
                    position: absolute;
                    z-index: 9;
                    display: flex;
                    font-family: 'Mauikea', sans-serif;

                    &:hover{

                        span{
                          
                            opacity: 1;
                            
                        }

                        img{
                            transform: rotate(0deg);
                        }

                    }
                    span{
                        overflow: hidden;
                      
                        height: 30px;
                        opacity: 0;
                        transition: 0.5s;
                    }
                    
                    img{
                        width:30px;
                        height: 30px;
                        cursor: pointer;
                        transition: 0.5s;

                        transform: rotate(10deg);
                    }

                    &.la-rochelle{
                        top:50%;
                        left:25%;
                    }

                    &.arcachon{
                        bottom:27%;
                        //top:67%;
                        left:27%;
                    }

                    &.toulouse{

                        //top:72%;
                        bottom:23%;
                        left:45%;
                    }

                    &.sete{
                        //top:70%;
                        bottom:20%;
                        left:63%;
                        flex-direction: column-reverse;
                    }

                    &.lyon{
                        top:50%;
                        //left:67%;
                        right: 20%;
                        flex-direction: row-reverse;
                        img{
                            transform: rotate(-10deg);
                        }
                    }

                }
                .trace{
                    position: absolute;
                    z-index: 6;
                    width: 63%;
                    top:30%;
                    left:24%;

                    
                }

            }
        }
    }


}