body{

    div.le-projet{
        .container{
            max-width: 1080px;
            width: 90%;
            margin: 0 auto;

            display: flex;
            flex-wrap: wrap;
            gap: 20px;
            

            .content{
                width: calc(50% - 20px);

                @media screen and (max-width: 768px) {
                    width: 100%;
                }

                .titre{
                    position: relative;

                    @media screen and (max-width: 768px) {
                        margin: auto;
                    }
                    h2{
                        font-size: 25px;
                        text-transform: uppercase;
                        font-weight: 600;
                        margin-bottom: 20px;
                    }

                    span{
                        font-family: 'Mauikea', sans-serif;
                        font-size: 40px;
                        line-height: 40px;
                        color:rgba(88, 75, 75, 0.4);
                        position: absolute;
                        top:-30px;
                        left: -20px;
                        transform: rotate(-5deg);
                    }
                }
                

                p{
                    font-size: 16px;
                    line-height: 24px;
                    font-weight: 300;
                    margin-bottom: 20px;
                }

                .cta{
                    margin-top: 40px;
                }
                
            }

            .images{
                width: calc(50% - 20px);
                display: flex;
                gap: 20px;

                @media screen and (max-width: 768px) {
                    width: 100%;
                    margin-top: 40px;
                }

                .col{
                    width: calc(50% - 20px);

                    &.decalage{
                        padding-top: 140px;

                        @media screen and (max-width: 768px) {
                            padding-top: 70px;
                        }
        
                    }

                    .image{
                        overflow-x: hidden;
                        overflow-y: hidden;
                        
                        width: 100%;
                        aspect-ratio: 9/13;
                        margin-bottom: 20px;

                        img{
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                            object-position: center;
                        }

                    
                        &:hover{
                            img{
                                transform: scale(1.1) rotate(0.5deg);
                            }
                        }
                    }
                    
                }
            }
        }


    }

    div.parcours{
        margin-top: 50px;
        .container{
            max-width: 1080px;
            width: 90%;
            margin: 0 auto;
        

            .titre{
                position: relative;
                width: fit-content;
                margin: auto;
                margin-bottom: 20px;
                h2{
                    font-size: 25px;
                    text-transform: uppercase;
                    font-weight: 600;
                    margin-bottom: 20px;
                }
                span{
                    font-family: 'Mauikea', sans-serif;
                    font-size: 40px;
                    line-height: 40px;
                    color:rgba(88, 75, 75, 0.4);
                    position: absolute;
                    top:-30px;
                    left: -20px;
                    transform: rotate(-5deg);
                }
            }

            .etapes{
                display: flex;
                flex-wrap: wrap;
                gap:20px;

                .etape{
                    width: calc(50% - 20px);
                    background-color: #584B4B;
                    padding: 20px;
                    height: 300px;
                    position: relative;
                    overflow: hidden;

                    cursor: pointer;

                    @media screen and (max-width: 768px) {
                        width: 100%;
                        height: 250px;
                    }
    

                    img{
                        width: 100%;
                        height: 100%;
                        position: absolute;
                        top:0px;
                        left: 0px;

                        object-fit: cover;
                        object-position: center;
                        z-index: 1;

                        transition: 1s ease-in-out;

                    }

                    .overlay{
                        width: 100%;
                        height: 100%;
                        position: absolute;
                        top:0px;
                        left: 0px;
                        background:linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(255,255,255,0) 100%);
                        z-index: 2;

                    }

                    &:hover{

                        img{
                            transform: scale(1.05) rotate(0.1deg);
                        }
                        
                        .voir-plus{
                            opacity: 1;
                            bottom: 10px;
                        }
                    }

                    .voir-plus{
                        position: absolute;
                        bottom:-10px;
                        opacity: 0;
                        transition-duration: 1s;
                        left:0px;
                        width: 100%;

                        z-index: 4;
                        color:white;
                        text-align: center;

                       

                        a{
                            display: block;
                            width: fit-content;
                            margin: auto;
                            text-align: center;
                            cursor: pointer;
                            padding-bottom: 5px;
                            border-bottom: 1px solid white;
                        }

                    }

                    .content{
                        position: relative;
                        color:white;
                        z-index: 3;
                       text-align: center;
                       
                       display: flex;
                       align-items: center;
                       justify-content: center;
                       flex-direction: column;
                       height: 100%;

                        h3{
                            font-family: 'Mauikea', sans-serif;
                            font-size: 50px;
                            line-height: 50px;
                            margin-bottom: 5px;
                        }

                        .infos{
                            font-size: 20px;
                        }

                        
                    }
                }

            }


           
            
     
        }
    }

    div.suivi{
        margin-top: 100px;
        margin-bottom: 100px;
        
        .container{
            max-width: 1080px;
            width: 90%;
            margin: 0 auto;

            display: flex;
            flex-wrap: wrap;
            gap: 40px;

            @media screen and (max-width: 768px) {
                flex-direction: column-reverse;
            }



            .map-suivi{
                width: calc(60% - 40px);
                height: 500px;
                background-color: #584B4B;

                @media screen and (max-width: 768px) {
                    width: 100%;
                }

                #map-suivi{
                    width: 100%;
                    height: 100%;
                }
            }
            
            .infos{
                width: calc(40% - 40px);
                padding-top: 30px;

                

                @media screen and (max-width: 768px) {
                    padding-top: 0px;
                    width: 100%;
                }

                .titre{
                    position: relative;
                    width: fit-content;

                    @media screen and (max-width: 768px) {
                        margin: 0 auto;
                    }
                   
                    margin-bottom: 20px;
                    h2{
                        font-size: 25px;
                        text-transform: uppercase;
                        font-weight: 600;
                        margin-bottom: 20px;
                    }
                    span{
                        font-family: 'Mauikea', sans-serif;
                        font-size: 40px;
                        line-height: 40px;
                        color:rgba(88, 75, 75, 0.4);
                        position: absolute;
                        top:-30px;
                        left: -20px;
                        transform: rotate(-5deg);
                    }
                }
            }

            .info{
                width: 100%;
                background-color:white;
                margin: 15px 0;
              

                display: flex;
                flex-wrap: wrap;
                gap: 20px;
                justify-content: flex-start;
                align-items: center;
                padding: 20px;
                border-radius: 10px;
                box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
                transition-duration: 0.5s;

                &:hover{
                    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0);

                    img{
                        opacity: 0.9;
                    }
                }

                p{
                    font-weight: 600;
                    span{
                        font-size: 40px;
                    }
                }
               

                img{
                    width: 30px;
                    height: 30px;
                    opacity: 0.5;
                    transition-duration: 0.5s;
                }
            }
        }
    }

    div.instagram{

        margin-top: 50px;
        .container{
            max-width: 1080px;
            width: 90%;
            margin: 0 auto;
        }

        .titre{
            position: relative;
            width: fit-content;
            margin: auto;
            margin-bottom: 20px;
            h2{
                font-size: 25px;
                margin-bottom: 20px;
            }

            @media screen and (max-width: 768px) {
                width: 300px;
                text-align: center;
            }

            
            span{
                font-family: 'Mauikea', sans-serif;
                font-size: 40px;
                line-height: 40px;
                color:rgba(88, 75, 75, 0.4);
                position: absolute;
                bottom:-20px;
                right: -20px;
                transform: rotate(-5deg);
            }
        }

        .flux{
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            gap: 20px;
            margin-top: 50px;



            
            .post{
                width: calc(25% - 20px);
                cursor: pointer;

                &:hover .caption{
                    opacity:1;
                }


                .caption{
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    top: 0px;
                    right: 0px;
                    background-color: rgba(0,0,0,0.5);
                    color:white;
                    
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    opacity: 0;
                    transition: 0.5s;
                    
                    

                    p{
                        height: 100%;
                        width: 100%;
                        padding: 20px;
                        overflow-x: hidden;
                        overflow-y: auto;
                        text-align: center;
                        display: flex;
                        justify-content: center;
                    }
    

                }

                &.video:after{
                    content:url('../img/play-solid.svg');
                    filter: brightness(0) invert(1);
               
                    position: absolute;
                    top: 20px;
                    right: 20px;
                    opacity: 0.9;
                    width: 20px;
                    height: 20px;

                }

                @media screen and (max-width: 768px) {
                    width: calc(50% - 10px);
                }

                aspect-ratio: 1/1;
                background-color: whitesmoke;
                position: relative;

                img{
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    object-position: center;
                    top:0px;
                }
            }
        }
    }
}