@font-face {
  font-family: "Satoshi";
  src: url("../fonts/satoshi.ttf") format('ttf');
}

@font-face {
  font-family: "Mauikea";
  src: url("../fonts/mauikea.woff") format('woff');
}

html {
  scroll-behavior: smooth;
}

body {
  &.modal-open{
    overflow-y: hidden;
    overflow-x: hidden;
  }
  font-family: 'Satoshi';
  font-size: 16px;
  background: #FDFDFD;
  background-image: url("../img/bg.jpg");
  background-repeat: repeat;
  background-size: 400px 400px;
  font-weight: 300;
  font-family: 'Satoshi', sans-serif;
  overflow-x: hidden;
  width: 100vw;

}



h1, h2, h3, h4 {
  font-family: 'Satoshi', sans-serif;

  &.special {
    font-family: 'Mauikea', sans-serif;
  }
}

.cta{
  width: fit-content;
  padding: 10px 20px;
  &.border{
    border: 1px solid #000;
  }
}
