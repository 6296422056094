.modal-etape{
    
    display: flex;
    pointer-events: none;

    opacity: 0;
    transition: 0.5s;

    &.active{
       
        opacity: 1;
        pointer-events: all;
    }
    
   

    width: 100vw;
    height: 100vh;
    position: fixed;
    top:0px;
    z-index: 1000 ;
    align-items: center;
    justify-content: center;
    




    .modal-content{
        width: 1080px;
        max-width: 90vw;
        background-color: white;
        padding: 20px;

        display: flex;
        flex-wrap: wrap;
        gap:20px;
        flex-direction: row-reverse;
        justify-content: space-between;

        @media screen and (max-width: 768px) {
            flex-direction: inherit;
        }
        

        max-height: 90vh;
        overflow: auto;

        .map{
            width: calc(50% - 20px);

            @media screen and (max-width: 768px) {
                width: 100%;
                aspect-ratio: 1;
            }
            min-height: 300px;
            max-height: 100%;
            background-color: aqua;
        }

        .prev, .next{
            cursor: pointer;
        }

        .descriptif{
            width: calc(50% - 20px);

            @media screen and (max-width: 768px) {
                width: 100%;
            }
            position: relative;
            padding-bottom: 30px;

            p.etape{
                font-size: 25px;
                text-transform: uppercase;
                font-weight: 500;
                line-height: 30px;
                margin: 0 0;
            }

            p{
                margin: 20px 0px;
            }

            h3{
                font-size: 40px;
                font-family: 'Mauikea', sans-serif;
            }

            nav{
                display: flex;
                position: absolute;
                justify-content: space-between;
                bottom: 0px;
                height: 20px;
                width: 100%;
            }
        }







    }

    .overlay{
        width: 100%;
        height: 100%;
        background-color:rgb(0,0,0,0.5) ;
        position: absolute;
        top:0px;
        left: 0px;
        z-index: -1;
        cursor: pointer;
    }





    
}

