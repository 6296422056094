body header{
    width: 100vw;
    
    nav{
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;

        padding: 10px 50px;
        height: 100px;
        

        ul{
            display: flex;
            align-items: center;
            gap: 20px;
            font-size: 16px;
            text-transform: uppercase;

            @media screen and (max-width: 768px) {
                display: none;
            }

            a.disabled{
                pointer-events: none;
                opacity: 0.4;
            }
        }

        .logo{
            max-width: 270px;

            @media screen and (max-width: 768px) {
                width: 200px;
            }

            img{
                width: 100%;
            }
        }
    }



}